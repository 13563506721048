import Fuse from 'fuse.js';
import Store from 'store';
import vtxDomReady from './common/helpers';
import TomSelect from 'tom-select/dist/js/tom-select.complete.min.js';

const GlobalSearch = {
  searchOptions, // eslint-disable-line
  tomSelectLookup: [],
  inputSelector: '.wrapper-search-autocomplete input.search-field',
  submitSelector: '.wrapper-search-autocomplete button[type=submit]',
  baseUrl: `${location.protocol}//${location.host}`,
  redirectUrl: '',
  fuseOptions: {
    keys: [
      {
        name: 'Title',
        weight: 0.5,
      },
      {
        name: 'Tags',
        weight: 0.1,
      },
      {
        name: 'ManualTags',
        weight: 0.6,
      },
    ],
    minMatchCharLength: 2,
    threshold: 0.2,
    distance: 500,
  },
  init() {
    let storeSearchIndexVersion = Store.get(
      `searchIndexVersion_${this.searchOptions.language}`
    );

    if (
      'undefined' !== typeof storeSearchIndexVersion &&
      this.searchOptions.autocompleteSearchVersion === storeSearchIndexVersion
    ) {
      this.jsonIndex = Store.get(
        `searchJsonIndex_${this.searchOptions.language}`
      );
      this.fuseIndex = Fuse.parseIndex(
        Store.get(`searchIndex_${this.searchOptions.language}`)
      );
      this.initFuseSearchEngine();
    } else {
      fetch(`/wp-json/search/index/${this.searchOptions.language}`)
        .then((response) => response.json())
        .then((jsonIndex) => {
          this.jsonIndex = jsonIndex;
        })
        .then(() => {
          this.fuseIndex = Fuse.createIndex(
            this.fuseOptions.keys,
            this.jsonIndex
          );
        })
        .then(() => {
          this.initFuseSearchEngine();
        })
        .catch((error) => {
          console.log('error: ', error);
        });
    }
  },
  initFuseSearchEngine() {
    if (this.jsonIndex && this.fuseIndex) {
      this.fuseEngine = new Fuse(
        this.jsonIndex,
        this.fuseOptions,
        this.fuseIndex
      );
      this.initSearchFields();
    }
  },
  fireSearch() {
    setTimeout(() => {
      //adding timeout to let the time for the hook `item_add` to be triggered
      if (this.redirectUrl.length > 0) {
        window.location.href = this.redirectUrl;
      }
    }, 600);
  },
  initSearchFields() {
    document.querySelectorAll(this.inputSelector).forEach((searchInput) => {
      let currentTomSelect = new TomSelect(searchInput, {
        maxItems: 1,
        maxOptions: 5,
        valueField: 'url',
        labelField: 'Title',
        searchField: 'Title',
        optionClass: 'search-dropdown__item',
        itemClass: 'search-field__item',
        create: true,
        createOnBlur: true,
        hideSelected: true,
        persist: false,
        closeAfterSelect: true,
        addPrecedence: true, //"Search ###" option is selected by default
        plugins: ['restore_on_backspace'],
        shouldLoad: (query) => {
          if (query.length < 2) return false;
          return true;
        },
        load: (query, callback) => {
          let fuseResults = this.fuseEngine.search(query);
          let fuseResultsToReturn = [];
          fuseResults.forEach((fuseResult) => {
            fuseResult.item.url = `${this.searchOptions.baseUrl}?page_id=${fuseResult.item.ID}`;
            fuseResultsToReturn.push(fuseResult.item);
          });
          callback(fuseResultsToReturn);
        },
        render: {
          option_create: (data, escape) => {
            return `<div class="create search-dropdown__item search-dropdown__item--find">${
              this.searchOptions.searchQueryLabel
            } <strong>${escape(data.input)}</strong>&hellip;</div>`;
          },
          no_results: () => {
            return `<div class="no-results search-dropdown__item">${this.searchOptions.noSuggestionFoundLabel}</div>`;
          },
        },
        onFocus: () => {
          currentTomSelect.clear();
          currentTomSelect.clearOptions();
        },
      });

      /* extracting the redirect url from the add action */
      currentTomSelect.on('item_add', (value) => {
        this.redirectUrl =
          value.indexOf('http') === 0
            ? value
            : `${this.searchOptions.searchPageLink.url}/?_search_keyword=${value}`;
      });

      /* Handling submit button */
      let submitButton = searchInput
        .closest('.wrapper-search-autocomplete')
        .querySelector(this.submitSelector);
      if (submitButton) {
        submitButton.addEventListener('click', () => {
          this.fireSearch();
        });
      }

      /* Handling option click */
      currentTomSelect.dropdown_content.addEventListener('click', () => {
        this.fireSearch();
      });

      /* handling enter press  */
      currentTomSelect.control_input.addEventListener('keydown', (e) => {
        if (e.keyCode === 13) {
          this.fireSearch();
        }
      });

      this.tomSelectLookup.push(currentTomSelect);
    });
  },
};

vtxDomReady(() => {
  GlobalSearch.init();
});
